<template>
	<div class="">
		<div>
			<highcharts id="comprehensiveHazardPoint" :options="chartOptions" style="height: 600px;"></highcharts>
			<!-- <button @click="adds">添加</button> -->
			<!-- <div style="height: 400px;" id="main2"></div> -->
		</div>
		<el-dialog title="设置值" :visible.sync="dialogFormVisible" width="30%">
			<el-form :model="form" :rules="rules" ref="ruleForm">
				<el-form-item label="当前温度(℃)" label-width="120px" prop="T_t">
					<el-input v-model="form.T_t" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="DelData">删 除</el-button>
				<el-button type="primary" @click="submitForm('ruleForm',1)">确 定(不刷新)</el-button>
				<el-button type="primary" @click="submitForm('ruleForm',2)">确 定(刷新)</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		TaskDataUp,
		TaskDataDel
	} from '../../../../api/CorpManage.js'
	var Highcharts = require('highcharts');
	require('highcharts/modules/exporting')(Highcharts);
	let moment = require('moment');
	export default {
		props: ['echartData', 'SetFrom'],
		data() {
			return {
				listdatas: [],
				MaxHum: 0, //最高温度
				MinHum: 0, //最低湿度
				AveHum: 0, //平均湿度
				Allnum: null, // 求和
				AllData: [], //所有的一维数组[0,1,2,3,]
				ViewData: {
					T_task_id: this.echartData.T_task_id,
					T_sn: null,
					Time_start: '',
					Time_end: this.echartData.Time_end,
					page_z: 9999
				},
				dialogFormVisible: false, //点击标记点的修改
				form: {
					T_task_id: this.$store.state.T_task_id.T_task_id,
					Id: '',
					T_t: '',
					T_rh: '',
					T_time: '',
				},
				rules: {
					T_t: [{
						required: true,
						message: '请设置值',
						trigger: 'blur'
					}],
				},
				EchartsData: [],
				EchartsList: this.echartData,
				SetFroms: null,
			}
		},
		watch: {
			SetFrom: {
				handler() {
					var _this = this
					_this.SetFroms = _this.SetFrom
				},
				deep: true,
				immediate: true,
			},
			EchartsList: {
				handler() {
					var _this = this
					this.$nextTick(() => {
						_this.getData()
					})
				},
				deep: true,
				immediate: true,
			},
		},
		created() {
			Highcharts.setOptions({
				lang: {
					resetZoom: '重置缩放比例',
					resetZoomTitle: '重置缩放比例'
				}
			});
		},
		computed: {
			chartOptions() {
				var _this = this
				return {
					chart: {
						zoomType: 'x',
						panning: true,
						panKey: 'shift',
						type: 'spline',
					},
					time: {
						useUTC: false
					},
					credits: {
						enabled: false // 禁用版权信息
					},
					boost: {
						useGPUTranslations: true
					},
					title: {
						text: '冷链温度曲线图'
					},
					subtitle: {
						useHTML: true,
						text: `<div style="display: flex;align-items: center;justify-content: center;color: rgb(63, 177, 124);">
							<div style="margin-right: 10px;">最高温度:${_this.MaxHum}℃</div>
							<div style="margin-right: 10px;">最低温度:${_this.MinHum}℃</div>
							<div style="margin-right: 10px;">平均温度:${_this.AveHum}℃</div>
						</div>`
					},
					lang: {
						viewFullscreen: "全屏",
						printChart: "打印图表",
						downloadJPEG: "下载JPEG图片",
						downloadPDF: "下载PDF文件",
						downloadPNG: "下载PNG文件",
						downloadSVG: "下载SVG文件",
					},
					// legend: {
					// 	align: 'left',
					// 	verticalAlign: 'middle',
					// 	layout: 'vertical',
					// 	itemMarginTop: 5,
					// 	itemMarginBottom: 5,
					// },
					xAxis: {
						title: {
							text: '时间'
						},
						type: 'datetime',
						dateTimeLabelFormats: {
							millisecond: '%H:%M:%S.%L',
							second: '%H:%M:%S',
							minute: '%H:%M',
							hour: '%H:%M',
							day: '%m-%d',
							week: '%m-%d',
							month: '%Y-%m',
							year: '%Y'
						}
					},
					yAxis: {
						title: {
							text: '温度℃'
						},
						plotLines: _this.SetPling()
					},
					tooltip: {
						valueSuffix: ' ℃',
						backgroundColor: '#3d4b61', // 背景颜色
						style: { // 文字内容相关样式
							color: "#fff",
							fontSize: "12px",
							fontWeight: "blod",
							fontFamily: "Courir new"
						},
						crosshairs: [ //显示鼠标放上去的时候显示的提示标线
							{
								width: 1,
								color: '#ccc'
							},
						],
						dateTimeLabelFormats: {
							millisecond: '%H:%M:%S.%L',
							second: '%Y-%m-%d %H:%M:%S',
							minute: '%Y-%m-%d %H:%M:%S',
							hour: '%Y-%m-%d %H:%M:%S',
							day: '%Y-%m-%d %H:%M:%S',
							week: '%Y-%m-%d %H:%M:%S',
							month: '%Y-%m-%d %H:%M:%S',
							year: '%Y-%m-%d %H:%M:%S'
						}
					},
					plotOptions: {
						series: {
							cursor: 'pointer',
							point: {
								events: {
									click: function() {
										console.log(this.series, this.index, this.series.name); //index为当前对象在数组中的索引，name为他的id
										var result = _this.listdatas.find((v) => {
											return v.name == this.series.name;
										});
										_this.form.Id = result.data[this.index][2]
										_this.form.T_t = result.data[this.index][1]
										_this.form.T_rh = result.data[this.index][5]
										_this.form.T_time = moment(new Date(result.data[this.index][0])).format(
											'YYYY-MM-DD HH:mm:ss')
										_this.dialogFormVisible = true
										console.log(_this.form)
									}
								}
							}
						}
					},
					series: _this.listdatas,
					responsive: {
						rules: [{
							condition: {
								maxWidth: 500
							},
							chartOptions: {
								legend: {
									layout: 'horizontal',
									align: 'center',
									verticalAlign: 'bottom'
								}
							}
						}]
					},
				};
			}
		},
		methods: {
			SetPling() {
				var _this = this
				if(_this.SetFroms.Temper_T==='' && _this.SetFroms.Temper_B===''){//全为空
					return []
				}else if(_this.SetFroms.Temper_T===''){//上限为空
					return [{
							color: '#FF7804', //线的颜色，定义为红色
							dashStyle: 'DashDot', //默认值，这里定义为实线
							value: _this.SetFroms.Temper_B, //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
							width: 4, //标示线的宽度，2px
							label: {
								text: `温度下限（${_this.SetFroms.Temper_B}℃）`, //标签的内容
								color: '#FF7804', //线的颜色，定义为红色
								align: 'right', //标签的水平位置，水平居左,默认是水平居中center
								// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
							}
						},
					]
				}else if(_this.SetFroms.Temper_B===''){//下限为空
					return [{
							color: '#FF1D00', //线的颜色，定义为红色
							dashStyle: 'DashDot', //默认值，这里定义为实线
							value: Number(_this.SetFroms.Temper_T), //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
							width: 4, //标示线的宽度，2px
							label: {
								text: `温度上限（${_this.SetFroms.Temper_T}℃）`, //标签的内容
								align: 'right', //标签的水平位置，水平居左,默认是水平居中center
								// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
							}
						}
					]
				}else{//上下限都有值
					return [{
							color: '#FF1D00', //线的颜色，定义为红色
							dashStyle: 'DashDot', //默认值，这里定义为实线
							value: Number(_this.SetFroms.Temper_T), //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
							width: 4, //标示线的宽度，2px
							label: {
								text: `温度上限（${_this.SetFroms.Temper_T}℃）`, //标签的内容
								align: 'right', //标签的水平位置，水平居左,默认是水平居中center
								// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
							}
						},
						{
							color: '#FF7804', //线的颜色，定义为红色
							dashStyle: 'DashDot', //默认值，这里定义为实线
							value: _this.SetFroms.Temper_B, //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
							width: 4, //标示线的宽度，2px
							label: {
								text: `温度下限（${_this.SetFroms.Temper_B}℃）`, //标签的内容
								color: '#FF7804', //线的颜色，定义为红色
								align: 'right', //标签的水平位置，水平居左,默认是水平居中center
								// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
							}
						},
					]
				}
			},
			getData() {
				this.listdatas = []
				var _this = this
				_this.AllData = []
				for (var j = 0; j < _this.EchartsList.length; j++) {
					var objs = {
						name: _this.EchartsList[j][0].T_id,
						data: []
					}
					for (var i = 0; i < _this.EchartsList[j].length; i++) {
						var Arrs = _this.EchartsList[j]
						var times1 = new Date(Arrs[i].T_time).getTime()
						objs.data.push([times1, Arrs[i].T_t, Arrs[i].ID, Arrs[i].T_id, Arrs[i].T_sn, Arrs[i].T_rh]);
						_this.AllData.push(Arrs[i].T_t)
					}
					_this.listdatas.push(objs);
				}
				var ArrQ = _this.AllData.flat()
				_this.MaxHum = Math.max(...ArrQ)
				_this.MinHum = Math.min(...ArrQ)
				ArrQ.reduce(function(pre, curr) { //求和
					_this.Allnum = pre + curr;
					return _this.Allnum;
				});
				_this.AveHum = parseFloat(_this.Allnum / ArrQ.length).toFixed(1)
				return;
			},
			submitForm(formName,por) { //提交编辑或者新增按钮
				var _this = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						TaskDataUp(_this.form).then(res => {
							console.log(res)
							if (res.data.Code === 200) {
								_this.$message({
									type: 'success',
									message: 'ok，修改成功！'
								});
								if(por===2){
									this.$emit("giveFather", true);
								}
								_this.dialogFormVisible = false
							} else {
								this.$emit("giveFather", false);
							}
						})
					} else {
						_this.$message({
							type: 'error',
							message: '哎呀，验证未通过，检查是否未空值！'
						});
						return false;
					}
				});
			},
			DelData() {
				var _this = this
				this.$confirm('此操作将删除数据, 是否继续?', '重置提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					showClose: false
				}).then(() => {
					TaskDataDel({
						T_task_id: _this.form.T_task_id,
						Id: _this.form.Id
					}).then(res => {
						console.log(res)
						if (res.data.Code === 200) {
							this.$emit("giveFather", true);
							_this.$message({
								type: 'success',
								message: '数据删除成功'
							});
							_this.dialogFormVisible = false
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消数据删除'
					});
				});
			},
		}
	}
</script>

<style>
	.echarts_Editing_H>div {
		margin: 0 10px;
		font-size: 14px;
	}
</style>
