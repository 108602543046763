<template>
	<!-- 数据编辑 -->
	<div class="DataEditing">
		<div class="DataEditing_Nav">
			<el-checkbox v-model="checkAll" class="checkAlls" @change="handleCheckAllChange">{{!checkAll?'全选':'取消'}}
			</el-checkbox>
			<label style="font-size: 14px;color:#409EFF;text-decoration: underline;cursor: pointer;"
				@click="handleCheckFanChange">反选</label>
			<label style="font-size: 14px;margin-left: 20px;">({{NavList.length}})</label>
			<div class="DataEditing_Nav_Conter">
				<div class="DataEditing_Nav_Conter1" v-for="(item,index) in NavList" :key="index">
					<div class="DataEditing_Nav_checked">
						<el-checkbox v-model="item.checkeds" @change="handleCheckDanChange(item,index)"></el-checkbox>
					</div>
					<div class="DataEditing_Nav_All">
						<div class="DataEditing_Nav_All_Img">
							<img src="../../../../assets/img/wenshidu1.png" alt="图标" style="width: 100%;height: 100%;">
						</div>
						<div class="DataEditing_Nav_All_txt">
							<div style="display: flex;align-items: center;">
								<div style="margin-top: 2px;font-size: 14px;font-weight: bold;">
									ID:{{item.T_id}}
								</div>
								<div @click="SetDel(item)"
									style="font-size: 12px;text-decoration: underline;margin-left: 10px;transform: scale(0.8);color: red;cursor: pointer;">
									删除</div>
							</div>
							<div
								style="width: 120px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 2px;font-size: 12px;">
								{{item.T_sn}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="width: 15px;background: #f2f2f2;"></div>
		<div class="DataEditing_Conter">
			<div class="DataEditing_Conter1">
				<el-date-picker v-model="Times" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="Changes">
				</el-date-picker>
				<!-- <el-button type="primary" icon="el-icon-search" style="margin-left:10px;" @click="searchValue()">搜索
				</el-button> -->
				<el-button type="primary" icon="el-icon-circle-plus-outline" style="margin-left:10px;"
					@click="Editing=true">添加数据
				</el-button>
				<el-button type="primary" icon="el-icon-setting" style="margin-left:10px;" @click="TemHumidity=true">
					上下限设置
				</el-button>
				<el-upload action="/上传文件的接口" style="margin-left:10px;" :on-change="onChange" :auto-upload="false"
					:show-file-list="false" accept=".xls, .xlsx">
					<el-button type="primary" icon="el-icon-download">导入表格</el-button>
				</el-upload>
				<el-button type="primary" icon="el-icon-download" style="margin-left:10px;" @click="drawer=true">
					导入数据（冷链平台）
				</el-button>
				<el-button type="primary" icon="el-icon-upload" style="margin-left:10px;" @click="GetTaskDataExcel">
					导出表格
				</el-button>
			</div>
			<div class="DataEditing_Conter2" v-if="EchartsList.length!=0">
				<div style="flex: 1;width: 100%;">
					<temperature :echartData="EchartsList" :SetFrom="SetFrom" @giveFather="refresh"></temperature>
				</div>
				<div style="flex: 1;width: 100%;margin-top:100px;">
					<humidity v-if="hmiShow" :echartData="EchartsList" :SetFrom="SetFrom" @giveFather="refresh">
					</humidity>
					<div v-else @click="hmiShow=true"
						style="display: flex;justify-content: center;align-items: center;cursor: pointer;text-decoration: underline;color: red;">
						我要显示湿度曲线图
					</div>
				</div>
			</div>
			<div class="DataEditing_Conter2" v-else>
				<el-empty :image-size="100" description="暂无数据显示"></el-empty>
			</div>
		</div>
		<!--添加数据-->
		<el-dialog title="添加数据" :visible.sync="Editing" width="30%" top="0" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="ruleForm" label-width="50px">
				<el-form-item label="测点" prop="T_sn">
					<el-select v-model="form.T_sn" placeholder="请选择测点" @change="cedian">
						<el-option :label="'['+item.T_id+']'+item.T_sn" :value="item.T_sn+','+item.T_id"
							v-for="(item,index) in NavList" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="时间" prop="T_time">
					<el-date-picker v-model="form.T_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
						placeholder="选择日期时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="温度" prop="T_t" label-suffix="000">
					<el-input v-model="form.T_t"></el-input>
				</el-form-item>
				<el-form-item label="湿度" prop="T_rh">
					<el-input v-model="form.T_rh"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="Editing = false">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>

		<!--温湿度上下限设置-->
		<el-dialog title="温湿度上下限设置" :visible.sync="TemHumidity" width="25%" top="0" :close-on-click-modal="false">
			<el-form :model="SetFrom" label-width="78px" :inline="true">
				<div style="display: flex;align-items: center">
					<div style="flex: 1;">
						<el-form-item label="温度上限" style="width: 100%;margin-bottom: 0;">
							<el-input v-model="Temper.Temper_T"></el-input>
						</el-form-item>
					</div>
					<div style="flex: 1;margin-left: 10px">
						<el-form-item label="温度下限" style="width: 100%;margin-bottom: 0;">
							<el-input v-model="Temper.Temper_B"></el-input>
						</el-form-item>
					</div>
				</div>
				<div style="display: flex;align-items: center;m">
					<div style="flex: 1;">
						<el-form-item label="湿度上限" style="width: 100%;margin-bottom: 0;">
							<el-input v-model="Humidity.Humidity_T"></el-input>
						</el-form-item>
					</div>
					<div style="flex: 1;margin-left: 10px">
						<el-form-item label="湿度下限" style="width: 100%;margin-bottom: 0;">
							<el-input v-model="Humidity.Humidity_B"></el-input>
						</el-form-item>
					</div>

				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="TemHumidity = false">取消</el-button>
				<el-button type="primary" @click="Setsubmit()">立即设置</el-button>
			</div>
		</el-dialog>

		<el-dialog title="批量导入" :visible.sync="filesShow" width="30%" center :show-close="showClose"
			:close-on-click-modal="false">
			<div style="display: flex;align-items: flex-end;justify-content: center;">
				<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
					<el-progress type="circle" :width='100' :percentage="100" :format="format">
					</el-progress>
					<div style="margin-top: 5px;color: #999;font-size: 12px;font-weight: 500;">总数</div>
				</div>
				<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
					<el-progress type="dashboard" :width='150' :percentage="percentage" :color="colors"></el-progress>
					<div style="margin-top: 5px;color: #999;font-size: 12px;font-weight: 500;">当前进度</div>
				</div>
				<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
					<el-progress type="circle" :width='100' :percentage="100" :format="format1"></el-progress>
					<div style="margin-top: 5px;color: #999;font-size: 12px;font-weight: 500;">导入成功</div>
				</div>
			</div>
		</el-dialog>

		<el-drawer title="导入数据(冷链平台)" :visible.sync="drawer" direction="rtl">
			<div class="Tan-drawer">
				<el-steps :active="actives" simple>
					<el-step title="查询数据" icon="el-icon-search"></el-step>
					<el-step title="导入数据" icon="el-icon-upload"></el-step>
				</el-steps>
				<div class="Tan-drawer-conter">
					<div class="Tan-drawer-conter1" v-if="actives===1">
						<el-form :model="ruleForms" :rules="ruless" ref="ruleForms" label-width="80px">
							<el-form-item label="开始时间" prop="Time_start">
								<el-date-picker v-model="ruleForms.Time_start" type="datetime"
									value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="结束时间" prop="Time_end">
								<el-date-picker v-model="ruleForms.Time_end" type="datetime"
									value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="SN" prop="T_sn">
								<el-input v-model="ruleForms.T_sn"></el-input>
							</el-form-item>
							<el-form-item label="探头编号" prop="T_id">
								<el-input v-model="ruleForms.T_id"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="submitForms('ruleForms')">立即查询</el-button>
								<el-button @click="actives=2" v-if="Number(activdata_num)>10">下一步</el-button>
							</el-form-item>
						</el-form>
					</div>
					<div class="Tan-drawer-conter2" v-else>
						<el-form :model="ruleForms1" :rules="ruless1" ref="ruleForms1" label-width="120px">
							<el-form-item label="SN(显示名称)" prop="T_sn1" label-width="120px">
								<el-input v-model="ruleForms1.T_sn1"></el-input>
							</el-form-item>
							<el-form-item label="探头编号" prop="T_id1" label-width="120px">
								<el-input v-model="ruleForms1.T_id1"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="submitForms1('ruleForms1')">立即导入</el-button>
								<el-button @click="actives=1">上一步</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</el-drawer>
		<!-- <el-dialog title="加载图表" :visible.sync="loadShow" width="30%" center :show-close="showclose" :close-on-click-modal="false">
			<div style="display: flex;align-items: flex-end;justify-content: center;">
				<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
					<el-progress type="dashboard" :width='150' :percentage="Loadpercen" :color="colors"></el-progress>
					<div style="margin-top: 5px;color: #999;font-size: 12px;font-weight: 500;">{{loadNum}}/{{NavList.length}}当前进度</div>
				</div>
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
	import temperature from './echarts_Editing_T.vue'
	import humidity from './echarts_Editing_H.vue'
	import {
		TaskDataClassList,
		TaskDataAdd,
		ExcAdd,
		TaskDataExcel,
		TaskDataEchartsList,
		TaskDataColdVerify,
		DelTid
	} from '../../../../api/CorpManage.js'
	import fileDownload from 'js-file-download'
	import Axios from 'axios'
	let moment = require('moment');
	export default {
		components: {
			temperature,
			humidity
		},
		data() {
			return {
				// showclose:false,
				// loadShow:false,
				// loadNum:0,
				// Loadpercen:0,
				wsData: null,
				showClose: false,
				hmiShow: false,
				activdata: [],
				activdata_num: 0,
				activdata_page: 0,
				drawer: false,
				actives: 1,
				ruleForms: {
					Time_start: '',
					Time_end: '',
					T_sn: '',
					T_id: '',
				},
				ruleFormsFome: { //提交的
					Time_start: '',
					Time_end: '',
					T_snid: '',
					page: 1,
					page_z: 9999,
				},
				ruless: {
					Time_start: [{
						required: true,
						message: '选择开始时间',
						trigger: 'blur'
					}],
					Time_end: [{
						required: true,
						message: '选择结束时间',
						trigger: 'blur'
					}],
					T_sn: [{
						required: true,
						message: '输入sn1',
						trigger: 'blur'
					}],
					T_id: [{
						required: true,
						message: '输入探头编号',
						trigger: 'blur'
					}],
				},

				ruleForms1: {
					T_sn1: '',
					T_id1: '',
				},
				ruless1: {
					T_sn1: [{
						required: true,
						message: '输入sn2',
						trigger: 'blur'
					}],
					T_id1: [{
						required: true,
						message: '输入探头编号',
						trigger: 'blur'
					}],

				},

				colors: [{
						color: '#f56c6c',
						percentage: 20
					},
					{
						color: '#e6a23c',
						percentage: 40
					},
					{
						color: '#5cb87a',
						percentage: 60
					},
					{
						color: '#1989fa',
						percentage: 80
					},
					{
						color: '#67C23A',
						percentage: 100
					},
				],
				statusT: '',
				percentage: 0, //导入进度
				per_i: 0, //当前上传数
				per_num: 0, //当前上传总数
				filesShow: false,
				EchartsList: [], //传入子组件的数据数组
				ViewData: {
					T_task_id: this.$store.state.T_task_id.T_task_id,
					T_sn: 865901058809776,
					Time_start: '',
					Time_end: '',
					page_z: 9999
				},
				Times: null,
				Editing: false,
				TemHumidity: false,
				NavList: [],
				form: {
					T_task_id: this.$store.state.T_task_id.T_task_id,
					T_sn: '',
					T_time: null,
					T_t: null,
					T_rh: null,
					T_id: null
				},
				rules: {
					T_sn: [{
						required: true,
						message: '请选择测点',
						trigger: 'blur'
					}],
					T_time: [{
						required: true,
						message: '请选择时间',
						trigger: 'blur'
					}],
					T_t: [{
						required: true,
						message: '请输入温度',
						trigger: 'blur'
					}],
					T_rh: [{
						required: true,
						message: '请输入湿度',
						trigger: 'blur'
					}]
				},
				SetFrom: { //设置温湿度上限
					Temper_T: '', //设置湿度上限
					Temper_B: '',
					Humidity_T: '', //设置湿度上限
					Humidity_B: ''
				},
				Temper: {
					Temper_T: '', //设置湿度上限
					Temper_B: '',
				},
				Humidity: {
					Humidity_T: '', //设置湿度上限
					Humidity_B: ''
				},
				checkAll: false, //全选
			}
		},
		mounted() {
			this.PostTaskDataClassList()
		},
		methods: {
			SetDel(obj) {
				var that = this
				this.$confirm('此操作将永久数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					var objs = {
						T_task_id: that.$store.state.T_task_id.T_task_id,
						Id: obj.T_id
					}
					DelTid(objs).then(res => {
						if(res.data.Code===200){
							that.$message.success('删除成功');
							that.EchartsList = []
							that.PostTaskDataClassList()
						}else{
							that.$message.error('删除失败');
						}
					})
				}).catch(() => {
					that.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			submitForms(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ruleFormsFome.Time_start = this.ruleForms.Time_start
						this.ruleFormsFome.Time_end = this.ruleForms.Time_end
						this.ruleFormsFome.T_snid = this.ruleForms.T_sn + ',' + this.ruleForms.T_id + '|'
						console.log('valid', this.ruleFormsFome)
						this.activdata = []
						this.TaskDataColdVerifyApi(this.ruleFormsFome)
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			submitForms1(ruleForms1) { //冷链平台的立即导入
				var that = this
				this.$refs[ruleForms1].validate((valid) => {
					if (valid) {
						that.per_num = that.activdata.length
						that.filesShow = true
						that.TaskDataAddZhongjian()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async TaskDataAddZhongjian() {
				this.per_i = 0
				this.percentage = 0
				this.showClose = false
				for (let i = 0; i < this.activdata.length; i++) {
					let that = this
					let sheetData = {
						T_task_id: that.$store.state.T_task_id.T_task_id,
						T_id: that.ruleForms1.T_id1,
						T_sn: that.ruleForms1.T_sn1,
						T_t: that.activdata[i].T_t,
						T_rh: that.activdata[i].T_rh,
						T_time: that.activdata[i].T_time
					}
					let codes = await that.TaskDataAddApi(sheetData)
					if (codes.data.Code == 200) {
						that.per_i = Number(that.per_i) + 1
						that.percentage = parseFloat(Number((that.per_i / that.per_num) * 100)).toFixed(0) - 0
					}
					if (i + 1 == this.per_num) {
						that.SetForecah()
						that.$message({
							type: 'success',
							message: `ok，导入数据成功！ 成功${that.per_i}条！ 失败${Number(this.per_num)-Number(that.per_i)}条`
						});
						that.drawer = false
						that.actives = 1
						that.showClose = true
					}
				}
			},
			TaskDataAddApi(sheetData) { //add接口
				return new Promise((resolve) => {
					TaskDataAdd(sheetData).then(function(res) {
						resolve(res);
					})
				});
			},
			TaskDataColdVerifyApi(obj) {
				var _this = this
				TaskDataColdVerify(obj).then(res => {
					console.log('数据', res.data)
					var Arr = res.data.Data.DeviceSensor_data
					if (Arr === null || res.data.Data.Num <= 10) {
						this.$notify.error({
							title: '查询失败',
							message: '条数为空或不足10条，请重选时间在查询'
						});
						_this.activdata_num = res.data.Data.Num
						return
					} else {
						Arr.forEach(function(obj) {
							_this.activdata.push(obj)
						})
						_this.$notify({
							title: '查询成功',
							message: '共' + res.data.Data.Num + '条数据，点击下一步导入数据',
							type: 'success'
						});
						_this.activdata_num = res.data.Data.Num
					}
				})
			},
			refresh(temp) {
				var _this = this
				if (temp) {
					_this.EchartsList = []
					_this.SetForecah()
				}
			},
			format() {
				return `${this.per_num }条`
			},
			format1() {
				return `${this.per_i }条`
			},
			GetTaskDataExcel() { //导出图表数据
				const Bcheckeds = this.NavList.some(item => item.checkeds === true) //若存在未选择返回true   false时不存在未选中（全选了）
				console.log('导出', Bcheckeds)
				if (Bcheckeds) {
					var Arr = []
					this.NavList.forEach(function(item) {
						if (item.checkeds) {
							Arr.push(item.T_sn)
						}
					})
					var pameag = {
						T_task_id: this.$store.state.T_task_id.T_task_id,
						T_sn_list: Arr.join(','),
						Time_start: this.ViewData.Time_start,
						Time_end: this.ViewData.Time_end
					}
					console.log('检查pameag', pameag)
					TaskDataExcel(pameag).then(res => {
						if (res.data.Code === 200) {
							Axios({
								method: 'get',
								url: res.data.Data,
								responseType: 'blob'
							}).then(res => {
								this.$message({
									message: "导出数据表格成功",
									showClose: true,
									type: "success"
								});
								fileDownload(res.data, moment(new Date()).format('YYYYMMDDHHmmss') +
									'.xlsx');
							});
						}
					})
				} else {
					this.$confirm('导出数据表格失败，请选择至少一条SN在导出数据', '失败', {
						confirmButtonText: '立即选择',
						type: 'error',
						center: true,
						showClose: false,
						showCancelButton: false
					})
				}
			},
			// ----------以下为导入Excel数据功能--------------
			// 文件选择回调
			onChange(file, fileList) {
				console.log('文件选择回调', fileList);
				this.per_i = 0
				this.percentage = 0
				this.showClose = false
				this.$confirm('批量导入数据，确认所有数据是否正确?', '提示', {
					confirmButtonText: '确认导入',
					cancelButtonText: '取消',
					type: 'warning',
					center: true,
					showClose: false
				}).then(() => {
					this.percentage = 0
					this.fileData = file; // 保存当前选择文件
					this.readExcel(); // 调用读取数据的方法
					this.filesShow = true
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消批量导入'
					});
				});

			},
			// 读取数据
			async readExcel(e) {
				console.log(e);
				let that = this;
				that.showClose = false
				let files = that.fileData;
				console.log(files);
				if (!files) {
					//如果没有文件
					return false;
				} else if (!/.(xls|xlsx)$/.test(files.name.toLowerCase())) {
					that.$message.error("上传格式不正确，请上传xls或者xlsx格式");
					return false;
				}
				let fileReader = new FileReader();
				fileReader.onload = ev => {
					let data = ev.target.result;
					let workbook = that.XLSX.read(data, {
						type: "binary"
					});
					let wsname = workbook.SheetNames[0]; //取第一张表
					that.wsData = that.XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容
					that.setdata()
				};
				// 如果为原生 input 则应是 files[0]
				fileReader.readAsBinaryString(files.raw);
			},

			async setdata() {
				let that = this
				let Arr = this.wsData
				this.per_num = Arr.length
				let count = 100; //每次100条
				let countNum = 0; //当前分到的数量，用于计算总量

				let sheetData = {
					T_task_id: that.$store.state.T_task_id.T_task_id,
					T_Data: ''
				}
				console.log('总数',Arr)
				for (let i = 0; i < Arr.length; i += count) {
					countNum = Arr.slice(i, i + count)
					for (let j = 0; j < countNum.length; j++) {
						if (countNum[j]["SN"] === undefined || countNum[j]["编号"] === undefined) {
							this.$message.error('请检查你的Excel文件中SN或编号是否正确')
							that.filesShow = false
							return
						}
						if (countNum[j]["温度℃"] === undefined) {
							countNum[j]["温度℃"] = '0'
						}
						if (countNum[j]["湿度%"] === undefined) {
							countNum[j]["湿度%"] = '0'
						}

						var newStr = countNum[j]["SN"] + '|' + countNum[j]["编号"] + '|' + countNum[j]["温度℃"] + '|' + countNum[j]["湿度%"] + '|' + countNum[j]["记录时间"] + '?'
						sheetData.T_Data = sheetData.T_Data + newStr
					}
					let obj = await that.TaskAdds(sheetData)
					if (obj) {
						sheetData.T_Data = ''
						if (obj.data.Code === 200) {
							that.per_i = Number(that.per_i) + Number(countNum.length)
						}
					}
					if (that.per_i === Arr.length) {
						that.$message.success(`数据导入结束，成功${that.per_i} , 失败${Number(Arr.length) - Number(that.per_i)}`)
						that.showClose = true
					}
					that.percentage = parseFloat(Number((that.per_i / that.per_num) * 100)).toFixed(0) - 0
				}	
				that.PostTaskDataClassList()
				// that.$refs.upload.value = "";
				
				// that.PostTaskDataClassList()
				// that.$refs.upload.value = "";
				// return
				// for (var i = 0; i < Arr.length; i++) {
				// 	if (that.wsData[i]["SN"] === undefined || that.wsData[i]["编号"] === undefined) {
				// 		this.$message.error('请检查你的Excel文件中SN或编号是否正确')
				// 		that.filesShow = false
				// 		return
				// 	}
				// 	if (that.wsData[i]["温度℃"] === undefined) {
				// 		that.wsData[i]["温度℃"] = '0'
				// 	}
				// 	if (that.wsData[i]["湿度%"] === undefined) {
				// 		that.wsData[i]["湿度%"] = '0'
				// 	}
					
				// 	if (dbz === parseInt(i / count)) {
				// 		var newStr = that.wsData[i]["SN"] + '|' + that.wsData[i]["编号"] + '|' + that.wsData[i]["温度℃"] + '|' + that.wsData[i]["湿度%"] + '|' + that.wsData[i]["记录时间"] + '?'
				// 		sheetData.T_Data = sheetData.T_Data + newStr
				// 	} else {
				// 		let obj = await that.TaskAdds(sheetData)
				// 		if (obj) {
				// 			sheetData.T_Data = ''
				// 			dbz = parseInt(i / count)
				// 			var newStr1 = that.wsData[i]["SN"] + '|' + that.wsData[i]["编号"] + '|' + that.wsData[i]["温度℃"] + '|' + that.wsData[i]["湿度%"] + '|' + that.wsData[i]["记录时间"] + '?'
				// 			sheetData.T_Data = sheetData.T_Data + newStr1
				// 			if (obj.data.Code === 200) {
				// 				that.per_i = Number(that.per_i) + Number(obj.data.Data)
				// 			}
				// 		}
				// 		console.log('返回===',i,Arr.length,obj)
				// 	}
					
				// 	if (i + 1 === Arr.length) {
				// 		let obj = await that.TaskAdds(sheetData)
						
				// 		if (obj) {
				// 			if (obj.data.Code === 200) {
				// 				that.per_i = Number(that.per_i) + Number(obj.data.Data)
				// 			}
				// 			that.$message.warning(`数据导入结束，成功${that.per_i} , 失败${Number(Arr.length) - Number(that.per_i)}`)
				// 			that.showClose = true
				// 		}
				// 	}
				// 	that.percentage = parseFloat(Number((that.per_i / that.per_num) * 100)).toFixed(0) - 0
				// }
				// that.PostTaskDataClassList()
				// that.$refs.upload.value = "";
			},

			TaskAdds(sheetData) {
				return new Promise(resolve => {
					ExcAdd(sheetData).then(function(res) {
						resolve(res);
					})
				});
			},

			cedian(e) {
				var obj = e.split(',')
				this.form.T_sn = obj[0]
				this.form.T_id = obj[1]
			},
			Changes(e) {
				// console.log('时间', e)
				var _this = this
				this.EchartsList = []
				if (e != null) {
					_this.ViewData.Time_start = e[0],
						_this.ViewData.Time_end = e[1]
					_this.NavList.forEach(function(item) {
						// console.log('检查是否为true', item.checkeds)
						if (item.checkeds) {
							_this.ViewData.T_sn = item.T_sn
							_this.POSTTASKDATA()
						}
					})
				} else {
					_this.ViewData.Time_start = '',
						_this.ViewData.Time_end = ''
					_this.SetForecah()
				}
			},
			PostTaskDataClassList() {
				var _this = this
				TaskDataClassList({
					T_task_id: this.$store.state.T_task_id.T_task_id,
					page_z: 9999
				}).then(res => {
					if (res.data.Code === 200) {
						var Arr = res.data.Data
						_this.NavList = []
						if (Arr != null) {
							Arr.forEach(function(obj) {
								obj.T_id = obj.T_id.toString().padStart(3, "0")
								obj.checkeds = false
								_this.NavList.push(obj)
							})
						}
					}
				})
			},
			submitForm(formName) { //提交编辑或者新增按钮
				// console.log('提交', this.form)
				var _this = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// console.log('验证通过')
						TaskDataAdd(_this.form).then(res => {
							_this.EchartsList = []
							if (res.data.Code === 200) {
								_this.SetForecah()
								_this.Editing = false
								_this.$message({
									type: 'success',
									message: 'ok，新增数据成功！'
								});
							}
						})
					} else {
						console.log('');
						this.$message({
							type: 'error',
							message: '哎呀，填写所有的选项哦！'
						});
						return false;
					}
				});
			},
			Setsubmit() {
				if (this.Temper.Temper_T === "" && this.Temper.Temper_B === "" && this.Humidity.Humidity_T === "" && this
					.Humidity.Humidity_B === "") {
					this.$message({
						type: 'error',
						message: '哎呀,至少填写一项!'
					});
					return
				} else if (Number(this.Temper.Temper_T) < Number(this.Temper.Temper_B)) {
					this.$message({
						type: 'error',
						message: '哎呀，温度设置异常，温度上限小于下限了!'
					});
					return
				} else if (Number(this.Humidity.Humidity_T) < Number(this.Humidity.Humidity_B)) {
					this.$message({
						type: 'error',
						message: '哎呀，湿度设置异常，湿度上限小于下限了!'
					});
					return
				} else {
					this.SetFrom.Humidity_T = this.Humidity.Humidity_T
					this.SetFrom.Humidity_B = this.Humidity.Humidity_B
					this.SetFrom.Temper_T = this.Temper.Temper_T
					this.SetFrom.Temper_B = this.Temper.Temper_B
					this.$message({
						type: 'success',
						message: '温湿度上下限设置成功'
					});
					this.TemHumidity = false
				}
			},


			// handleCheckAllChange(val) { //全选
			// 	var _this = this
			// 	if (val) { //当前未全选
			// 		this.$confirm('全选数据量过大，可能会出现卡顿，是否继续全选', '提示', {
			// 			confirmButtonText: '继续',
			// 			cancelButtonText: '取消',
			// 			type: 'warning'
			// 		}).then(() => {
			// 			this.NavList.forEach(function(item) {
			// 				item.checkeds = val
			// 			})
			// 			_this.EchartsList = []
			// 			for (var i = 0; i < _this.NavList.length; i++) {
			// 				(function(t) { // 注意这里是形参
			// 					setTimeout(function() {
			// 						_this.ViewData.T_sn = _this.NavList[t].T_sn
			// 						_this.POSTTASKDATA()
			// 					}, 100 * t); // 还是每秒执行一次，不是累加的
			// 				})(i, '其他参数') // 注意这里是实参，这里把要用的参数传进去
			// 			}
			// 		}).catch(() => {
			// 			this.checkAll = false
			// 			this.$message({
			// 				type: 'info',
			// 				message: '已取消全选操作'
			// 			});
			// 		});
			// 	} else {

			// 	}
			// },

			async handleCheckAllChange(val) {
				let that = this
				if (val) { //当前未全选
					// this.loadNum=0
					// this.Loadpercen=0
					// this.showclose = false
					// this.loadShow = true
					this.NavList.forEach(function(item) {
						item.checkeds = val
					})
					for (let i = 0; i < this.NavList.length; i++) {
						this.ViewData.T_sn = this.NavList[i].T_sn
						let obj = await that.getCode();
						// this.loadNum = i+1
						// this.Loadpercen = parseFloat(Number((this.loadNum/this.NavList.length) * 100)).toFixed(0) - 0
						// if(i===this.NavList.length-1){
						// 	this.showclose = true
						// }
						if (obj) {
							if (obj.data.Data.List != null) {
								var Arr1 = obj.data.Data.List.reverse()
								this.EchartsList.push(Arr1)
							} else {
								this.$notify({
									title: '警告',
									message: 'SN无数据或当前选择时间段无数据',
									type: 'warning'
								});
							}
						} else {
							break;
						}
					}
				} else { //当前已经全选，现在操作时全取消
					this.NavList.forEach(function(obj) {
						obj.checkeds = val
					})
					this.EchartsList = []
				}
			},
			getCode() {
				return new Promise(resolve => {
					setTimeout(() => {
						TaskDataEchartsList(this.ViewData).then(function(res) {
							resolve(res);
						})
					}, 1500)
				});
			},

			handleCheckDanChange(e) { //单选
				this.ViewData.T_sn = e.T_sn
				var _this = this
				const b = this.NavList.some(item => item.checkeds === false) //若存在未选择返回true   false时不存在未选中（全选了）
				this.checkAll = !b
				// const objs = this.NavList.find(item => item.T_sn === e.T_sn) //返回符合要求的那个数组-元素
				if (e.checkeds) {
					this.POSTTASKDATA()
				} else {
					var Arr1 = this.EchartsList
					Arr1.forEach(function(item, index) {
						if (e.T_sn === item[0].T_sn) {
							_this.EchartsList.splice(index, 1)
						}
					})
				}
			},
			handleCheckFanChange() { //反选
				var _this = this
				_this.EchartsList = []
				this.NavList.forEach(function(obj) {
					obj.checkeds = !obj.checkeds
				})
				let b = this.NavList.some(item => item.checkeds === false)
				b ? this.checkAll = false : this.checkAll = true
				this.SetForecah()
			},
			SetForecah() {
				var _this = this
				for (var i = 0; i < _this.NavList.length; i++) {
					(function(t) { // 注意这里是形参
						if (_this.NavList[t].checkeds) {
							setTimeout(function() {
								_this.ViewData.T_sn = _this.NavList[t].T_sn
								_this.POSTTASKDATA()
							}, 100 * t); // 还是每秒执行一次，不是累加的
						}
					})(i, '其他参数') // 注意这里是实参，这里把要用的参数传进去
				}
			},

			POSTTASKDATA() {
				var _this = this
				TaskDataEchartsList(this.ViewData).then(res => {
					// console.log('返回图表数据', res.data.Data)
					if (res.data.Data.List != null) {
						var Arr1 = res.data.Data.List.reverse()
						_this.EchartsList.push(Arr1)
					} else {
						_this.$notify({
							title: '警告',
							message: 'SN无数据或当前选择时间段无数据',
							type: 'warning'
						});
					}
					// console.log('数据图表数据', _this.EchartsList)
				})
			}
		}
	}
</script>

<style>
	@import url("../../../../assets/css/el-dialog.css");

	.Tan-drawer-conter {
		padding: 50px 10px;
	}

	.DataEditing {
		display: flex;
		height: 100%;
		overflow: hidden;
	}

	.DataEditing_Nav {
		background: #fff;
		width: 200px;
		border-radius: 5px;
		padding: 10px 20px;
		overflow-y: scroll;
	}

	.DataEditing_Nav_Conter1 {
		display: flex;
		align-items: center;
	}

	.checkAlls {
		padding: 15px 0 10px 0;
		margin-right: 20px
	}

	.DataEditing_Nav_All {
		display: flex;
		align-items: center;
		margin: 15px 0;
	}

	.DataEditing_Nav_All_Img {
		width: 20px;
		height: 20px;
		margin: 0 10px;
		border: 2px solid #DCDFE6;
		box-sizing: border-box;
		background: #DCDFE6;
		padding: 2px;
		border-radius: 5px;
	}

	.DataEditing_Conter {
		background: #fff;
		border-radius: 5px;
		padding: 10px;
		flex: 1;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
	}

	.DataEditing_Conter1 {
		display: flex;
		align-items: center;
	}

	.DataEditing_Conter2 {
		padding: 50px 0;
		border: 1px solid #DCDFE6;
		box-sizing: border-box;
		border-radius: 4px;
		flex: 1;
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>
